.home-content {
    background-image: url("https://live.staticflickr.com/5012/5506441436_e4530a1749.jpg");
    background-blend-mode: hard-light;
    background-color: rgb(43, 0, 83);
    position: fixed;
    /* width: 100%; */
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    
    position: relative;
    height: 100vh;
    /* z-index: -3; */
  }


.featured {
    float: left;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5vh;
    text-align: left;
}

.featured-content {
    padding-top: 15%;
}

.main-caption {
    width: 90%;
    overflow: hidden;
    color: rgb(221, 221, 221);
    font-size: 400%;
    animation: example 5s steps(60, end); 
    color: rgb(57, 143, 255);
    color: rgb(81, 223, 68);
    color: rgb(158, 159, 255);
    margin-bottom: 0%; 
    /* margin-top: 15%; */
}

.secondary-caption {
    color: white;
    padding-left: 1%;
    font-size: large;
}



  
@keyframes example {
    from {
      width: 0;
      white-space: nowrap;
      /* border-right: 0.15em solid rgb(0, 63, 37); */
      color: rgb(158, 211, 255);
    }
    99% {
      white-space: nowrap;
    }
    to {
        color: rgb(158, 159, 255);

    }
}


.dark-bkg {
    background-color: rgb(22, 22, 22);
}

.medium-space-below {
    margin-bottom: 3vh;
}

.large-space-below {
    margin-bottom: 5vh;
}

.larger-space-below {
    margin-bottom: 10vh;
}

.content-card {
    float: left;
    color: white;
    text-align: left;
    width: 90%;
    margin-top: 0;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 0;
    /* padding-left: 5%; */
}

.text-card-inner {
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 4%;
    color: rgb(214, 214, 214);
    font-size: larger;
}

.text-card-inner h1{
    color: rgb(141, 115, 214);
    color: rgb(158, 159, 255);
}

.video-container {
    margin-top: 5%;
    text-align: center;
}

.video-src {
    height: 560px;
    width: 80%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home-outer::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.home-outer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.loader {
    width: 24px;
    height: 24px;
    border: 2px solid #FFF;
    border-bottom-color: rgb(158, 159, 255);
    border-radius: 50%;
    /* display: inline-block; */
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 