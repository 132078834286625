
.top-bar {
    position: fixed;
    top: 0;
    background-color: black;
    border-bottom: 1px solid rgb(158, 159, 255);
    height: 8vh;
    width: 100vw;
    /* line-height: 8vh; */
    text-align: left;
    min-height: 50px;
    z-index: 1;
}


.logo-container {
    float: left;
    height: 8vh;
    min-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    margin-left: 1vw;
    color: white;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-size: 150%;
    /* font-weight: bolder; */
}

.logo-container p {
    position: absolute;
    width: 100%;
    text-align: left;
}

.logo-container img {
    margin-top: 0.7vh;
    /* margin-left: 0.5vw; */
}

.options-container {
    float: right;
    margin-right: 3vw;
    overflow: hidden;
}


.topbar-icon {
    float: left;
    color: white;
    margin-left: 1vw;
}

.topbar-icon svg {
    height: 4vh;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.topbar-icon svg:hover {
    background-color: rgba(128, 128, 128, 0.295);
}