
@media all and (max-width: 1px){
    .product-card-container {
        background-color: rgb(133, 0, 0); 
        border-radius: 17px;
        width:  22vw;
        max-width: 350px;
        min-width: 300px;
        display: block;
        /* border: 1px solid rgb(133, 0, 0);  */
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5vh;
        /* float: left; */
        position: relative;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;


    }

    .product-thumbnail {
        height: 20vh;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-size: cover;
    }

    .product-info {
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 2vh;
        height: 34vh;
        min-height: 270px;
        max-height: 36vh;
        margin-top: 1vh;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        overflow: auto;
        display: table;
    }



    .product-desc-tr {
        display: table-row;
    }

    .progress-bar-container-tr {
        display: table-row;
    }

    .subscription-data-tr {
        display: table-row;
    }








    .product-desc {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        /* background-color: violet; */
    }


    .progress-bar-container {
        margin-top: 10%;
        display: table-cell;
        text-align: center;
        vertical-align: bottom;
        /* background-color: red; */
    }

    .subscription-data {
        display: table-cell;
        text-align: center;
        vertical-align: top;
        /* background-color: teal; */
    }



    .product-card-container h2 {
        position: relative;
        color: rgb(217, 235, 255);
        font-family: "Proxima Nova",Arial,sans-serif;
        line-height: 20px;
        font-weight: 400;

    }

    .product-card-container p {
        position: relative;
        color: rgb(217, 235, 255);
        font-size: 16px;
        flex-grow: 1;
        font-family: "Google Sans",sans-serif;
        font-weight: 500;

    }

    .product-card-container:hover {
        opacity: 90%;
        cursor: pointer;
    }

}
