@media all and (max-width: 1px){

    .sidebar-container {
        background-color: #212121;
        padding-bottom:2vh;
    }


    .logo-container {
        height: 8vh;
        min-height: 50px;
        line-height: 8vh;
        border-bottom: 0.05pt solid #333333;
        text-align: center;
        margin: auto;
        cursor: pointer;
        -webkit-user-select: none;       
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

    }

    .user-profile {
        display: flex;
        width: 100%;
        margin-top: 2vh;
        /* min-width: 202px;
        max-width: 222px; */
        margin-left: auto;
        margin-right: auto;
        /* border-bottom: 0.05pt solid #333333; */
    }

    .navbar-outer {
        margin-top: 2vh;
        width: 100%;
    }

    

    .logo-container p {
        position: absolute;
        width: 100%;
        text-align: left;
    }

    .logo-container img {
        margin-top: 0.7vh;
    }

    .sidebar-container h1 {
        font-family: 'Brush Script MT', cursive;
        color: rgba(241, 242, 255, 0.781);
    }

    .logo-container {
        font-size: 200%;
    } 

}