
@media all and (min-width: 1px){
    .product-card-container {
        background-color: rgb(133, 0, 0); 
        /* border-radius: 17px; */
        width: 32%;
        height: 25vh;
        /* max-width: 350px; */
        min-width: 200px;
        /* height: 54vh; */
        /* min-height: 380px; */
        display: block;
        /* border: 1px solid rgb(133, 0, 0);  */
        margin-left: 1vw;
        /* margin-right: 0.5vw; */
        margin-bottom: 5vh;
        float: left;
        position: relative;
        align-items: center;
        justify-content: center;
        /* box-shadow: 2px 2px 2px 2px lightgrey; */

    }

    .product-thumbnail {
        height: 20vh;
        /* border-top-left-radius: 15px;
        border-top-right-radius: 15px; */
        background-size: cover;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;   
    }

    .product-info {
        padding-left: 4%;
        padding-right: 4%;
        /* padding-top: 2vh; */
        /* height: 34vh; */
        /* min-height: 270px; */
        max-height: 36vh;
        /* border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px; */
        overflow: auto;
    }


    .product-info-hide-bar {
        margin-top: 0vh;
    }

    .product-info-show-bar {
        margin-top: 1vh;
    }

    .product-desc-tr {
        display: table-row;
    }

    .progress-bar-container-tr {
        display: table-row;
    }

    .subscription-data-tr {
        display: table-row;
    }



    .product-desc {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            /* background-color: violet; */
    }


    .progress-bar-container {
        /* margin-top: 10%; */
        /* display: table-cell;
        text-align: center;
        vertical-align: middle; */
        /* background-color: red; */
    }

    .subscription-data {
        display: table-cell;
        text-align: center;
        vertical-align: top;
        /* background-color: teal; */
    }



    .product-card-container h2 {
        position: relative;
        color: rgb(113, 222, 255);
        font-family: "Impact",Arial,sans-serif;
        /* line-height: 20px; */
        /* font-weight: 1800; */
        font-size: xx-large;
        margin: 0;
        vertical-align: center;
        -webkit-text-stroke: 2px black;
        text-align: center;


    }

    .product-card-container p {
        position: relative;
        color: #d2d2d2;
        font-size: 14px;
        flex-grow: 1;
        font-family: "Google Sans",sans-serif;
        font-weight: 500;

    }

    .product-card-container:hover {
        opacity: 90%;
        cursor: pointer;
    }

}