
.authpage-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    /* background-color: white; */
    /* justify-content: end; */
    justify-content: center;
    align-items: center;
    /* margin: 0 auto; */
    overflow-y: scroll;

    /* background-image: url("https://live.staticflickr.com/5012/5506441436_e4530a1749.jpg"); */
    background-blend-mode: hard-light;
    background-color: rgb(43, 0, 83);
    position: fixed;
    /* width: 100%; */
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    
    position: relative;
    height: 100vh;
}

.auth-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding-top: 10%; */

    box-sizing: border-box;
    border-left: solid 2px black;
    border-right: solid 2px black;
    /* margin-top: 2%; */
    /* margin-right: 20%; */
    /* justify-content: center; */
    /* background-color: #232323; */
    background-color: rgb(255, 255, 255);
    width: 350px;
    overflow-y:auto;
    /* border: 1px solid white; */
    /* height: 500px; */
}

.auth-container h1{
    /* color: white */
}

.auth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 10%;
    padding-bottom: 10%;
    box-sizing: border-box;
}

.auth-toggle {
    background-color: white;
    width: 80%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid rgb(199, 180, 217);
}

.toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 50%;
    height: 30px;
    border-radius: 10px;
}

.toggle-button:hover {
    /* background-color: rgb(199, 180, 217); */
    cursor: pointer;
}

.selected-color {
    background-color: rgb(199, 180, 217);
    background-color: black;
    color: white;
}

.auth-form {
    /* margin-top: 10%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

}

.text-input {
    width: 100%;
    height: 40px;
    border-radius: 10px; 
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    margin: 0 auto;
    border: 1px solid rgb(199, 180, 217);

}

.text-link {
    margin-top: 2%;
    margin-left: 4%;
    width: 100%;
    text-align: left;
    /* color: white; */
    cursor: pointer;
    font-size: smaller;
    
}

.submit-button {
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 40px;
    border-radius: 10px; 
    background-color: rgb(198, 186, 208);
    background-color: black;
    color: white;
    cursor: pointer;
}

.submit-button:hover {
    opacity: 70%;
}