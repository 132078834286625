
@media all and (max-width: 700px){

    .sidebar {
        margin-top: 8vh;
        height: 92vh;
        background-color: rgb(22, 22, 22);
        color: #d3e3fd;
        transform: translateX(-5vw);
        width: 0vw;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out; /* Add width if using CSS transitions */
        /* display: flex;
        justify-content: center;
        align-items: center; */
      }
      /* 
      .sidebar:hover {
        border-right: solid 1px rgb(158, 159, 255);
      } */
      /* 
      .sidebar :hover {
        border: solid 1px rgb(158, 159, 255);
      } */
      
      .sidebar.open {
        transform: translateX(0);
        width: 15vw;
      }
      .sidebar.closed {
        transform: translateX(-15vw);
        width: 0vw;
      }
      
      .sidebar-buttons-container {
        margin-top: 3vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        user-select: none;
      
      }
      
      .sidebar-button {
        width: 100%;
        /* background-color: #031525; */
        border-radius: 20px;
        font-weight: 500;
        font-size: 14px;
        /* line-height: 40px; */
        height: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .sidebar-button:hover {
        /* background-color: #0d2236; */
        border: solid 1px rgb(158, 159, 255);
      }
      
      .active-button {
        color:rgb(158, 159, 255);
        border: solid 1px rgb(158, 159, 255);
      }
      
      .button-icon {
        float: left;
        /* margin-left: 1vw; */
        width: 100%;
      }
      
      .button-icon svg {
        height: 40px;
        font-size: xx-large;
      }
      
      .button-text {
        float: left;
        /* margin-left: 1vw; */
        width: 100%;
        font-size: xx-small;
      }

}
