
    @media all and (max-width: 1px){
    .navbar-button {
        height: 6vh;
        min-height:30px;
        max-height: 40px;
        cursor: pointer;
        text-align: left;
        padding-left: 10%;
        display: flex;
    }

    .navbar-button:hover {
        background-color: rgba(88, 88, 88, 0.493);
        border-right: 10px solid rgb(51, 51, 51);
    }


    .navbar-button p {
        color: white;
        font-size: smaller;
        font-weight: 450;
        display: flex;
        align-items: center;
        
    }

    .navbar-button-active {
        background-color: rgba(133, 133, 133, 0.493);
        border-right: 10px solid rgb(133, 0, 0);
    }

    .navbar-button-active:hover {
        background-color: rgba(173, 173, 173, 0.493);
        border-right: 10px solid rgb(133, 0, 0);

    }


    .promotional {
        margin-top: 4vh;
        background-color: rgb(83, 11, 11);
        background-color: #470350;
    }

}