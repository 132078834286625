
@media all and (max-width: 1px){
    .page-container {
        width: 100%;
    }
    .notice-bar {
        display: flex;
        width: 100%;
        min-width: 100%;
        height: 7vh;
        min-height: 100px;
        overflow: hidden;
        background-color: rgb(78, 105, 255);
        background-color: rgb(133, 0, 73); 

    }

    .below-notice-bar {
        padding-left: 2vw;
        padding-right: 2vw;
        height: 92vh;
        overflow: scroll;
    }

    .title-bar {
        float: left;
        clear: both;
        margin-top: 2vh;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .cards-grid {
        padding-top: 3vh;
        clear: both;
        display: block;
        overflow-x: scroll;

        /* overflow: scroll;
        scrollbar-color: dark; */


    }

    .footer {
        display: block;
        /* color: rgb(224, 223, 255); */
        color: white;
        padding-bottom: 2vh;
    }


    .notice-bar p {
        display: flex;
        align-items: center;
        margin: 0 auto;
        color: white;
        /* color: rgb(255, 166, 166); */
        font-size: 20px;
        font-weight: bold;
        padding-left: 1vw;
        padding-right: 1vw;
    }


    .title-bar h2 {
        /* color: black; */
        color: white;
        flex-grow: 1;
        font-family: "Google Sans",sans-serif;
        font-size: 30px;
        font-weight: 500;
        line-height: 28px;
    }

    ::-webkit-scrollbar {
    width: 5px;
    height: 5px;;
    }

    ::-webkit-scrollbar-track {
    /* background: rgb(0, 3, 22); */
    background: #fafafa;
    background: #171919;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;       
        /* border: 1px solid orange;   */
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #555;

    }
}