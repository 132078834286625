

.courseTree-page-container {
    background-image: url("https://live.staticflickr.com/5012/5506441436_e4530a1749.jpg");
    background-blend-mode: hard-light;
    background-color: rgb(43, 0, 83);
    width: 100vw;
    height: 92vh;
    margin-top: 8vh;
    overflow-y: scroll;
}

.chapter-grid-container {
  height: 100%;
  width: 69vw;
  display: block;
  overflow: hidden;
  margin: 0 auto;
}

.chapter-grid {
    display: block;
    /* height: 50vh; */
    margin-top: 5vh;
    background-color: red;
;}

.chapter-container {
    height: 15vh;
    display: flex;
    align-items: center;
    background-color: #040c1d;
    border: 1px black solid;
    color: rgb(255, 232, 232);
    width:  16vw;
    max-width: 350px;
    min-width: 200px;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-bottom: 5vh;
    float: left;
    cursor: pointer;
}

.chapter-container:hover {
    background-color: #a4a2ff;
    color: #040c1d;
}

.chapter-text {
    margin: 0 auto;
}

.chapter-page-buttons {
    height: 5vh;
    min-height: 50px;
    display: block;
    margin-top: 5vh;
}

.single-button {
    color: rgb(206, 206, 206);
    float: left;
    margin-left: 1vw;
    height: 5vh;
    min-height: 25px;
    line-height: 5vh;
    text-align: center;
    display: block;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.up-to-date {
    display: none;
}


.user-controls {
    float: left;
    height: 5vh;
    width: 25vw;
}


.admin-controls {
    display: flex;
    justify-content: right;
    /* align-items: center; */
    float: right;
    height: 5vh;
    margin-right: 1vw;
    width: 41vw;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: block;
    width: 50px;
    height: 25px;
    min-height: 25px;
    float: left;
    margin-left: 1vw;

  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    /* padding-top: 3px;
    padding-bottom: 3px; */
    left: 4px;
    top: 4px;
    /* bottom: 4px; */
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgb(114, 0, 0);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }