@media all and (min-width: 1000px){

    body {
        /* scrollbar-width: none; */
    }

    .lesson-page-container {
        margin-top: 8vh;
        width: 100%;
        height: 92vh;
        background-color: black;
    }

    .lesson-bar-container {
        width: calc(19vw - 20px);
        height: 92vh;
        float: left;
    }

    .lesson-bar {
        height: 82vh;
        width: 100%;
        background-color: black;
        overflow-y: scroll;
        overflow-x: hidden;
        display: block;
        float: left;
        scrollbar-width: none;
    }

    .lesson-progress-bar {
        width: 94%;
        height: auto;
        float: left;
        margin-left: calc(3% + 10px);
        font-weight: bold;
    }

    .search-bar {
        background-color: black;
        color: white;
        width: 90%;
        height: 40px;
        border: none;
    }

    .search-bar input{
        margin-left: 50%;
    }

    input:focus {
        outline:none !important;
    }


    .add-lesson-prompt {
        width: 100% - 1px;
        height: 5vh;
        margin-top: 2px;
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;
        text-align: left;
    }

    .add-lesson-prompt p{
        margin-left: 10px;
    }

    .add-lesson-prompt input {
        font-size: medium;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }


    .lesson-button-list-admin {
        max-height: 75%;
        scrollbar-width: none;
    }

    .lesson-button-list-admin:hover {
        scrollbar-width: thin;
        scrollbar-color: rgb(47, 43, 51) rgb(18, 0, 36) ;
    }

    .lesson-button-list-user {
        max-height: 90%;
        overflow-y: scroll;
        scrollbar-width: none;
    }


    .lesson-button-list-user:hover {
        scrollbar-width: thin;
    }

    .lesson-button-list-user::-webkit-scrollbar {
        width: 0;
    }

    .lesson-button-list-user:hover::-webkit-scrollbar {
        width: 5px;
    }

    .lesson-button-list-admin::-webkit-scrollbar {
        width: 0;
    }

    .lesson-button-list-admin:hover::-webkit-scrollbar {
        width: 5px;
    }

    .lesson-button {
        width: 100% - 1px;
        height: 5vh;
        margin-top: 2px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        color: wheat;
        color: white;
        cursor: pointer;
        text-align: left;
        user-select: none;
    }

    .lesson-button p{
        margin-left: 10px;
        user-select: none;
        font-weight: 200;
    }

    .context-menu {
        position: absolute;
        /* width: 100px; */
        width: 5vw;
        min-width: 50px;
        background-color: #6b0000e3;
        border-top: 1px solid #300000;
        /* border-radius: 10px; */
    }


    .context-menu-button {
        color: white;
        border-bottom: 1px solid #300000;
        cursor: pointer;
    }

    .context-menu-button:hover {
        background-color: rgb(77, 0, 0);
    }

    .bg-green {
        background-color:  rgb(12, 100, 0);
    }

    .bg-amber {
        background-color:  rgb(255, 196, 0);
    }

    .bg-red {
        background-color: #6b0000;
    }

    .button-primary {
        background-color: #6b6dff8f;
    }

    .button-primary:hover {
        background-color: #4d4fff8f;
    }

    .admin-resources-button {
        color: white;
        width: 13%;
        min-width: 40px;
        user-select: none;
        cursor: pointer;
        margin: 0 auto;
        border: none;
        border-radius: 12px;
        margin-top: 3vh;
        margin-bottom: 3vh;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .admin-resources-button:hover {
        opacity: 0.9;
    }

    .admin-content-button {
        color: white;
        width: 15%;
        min-width: 60px;
        user-select: none;
        cursor: pointer;   
        margin: 0 auto;
        border: none;
        border-radius: 12px;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .admin-content-button:hover {
        opacity: 0.9;
    }


    .admin-button-container {
        margin: 0 auto;
        justify-content: space-evenly;
        margin-left: 5px;
        /* justify-content: center; */
    }

    .admin-button {
        /* background-color: #6b0000; */
        color: white;
        width: 40%;
        min-width: 60px;
        user-select: none;
        cursor: pointer;   
        margin: 0 auto;
        border: none;
        border-radius: 12px;
        margin-top: 3vh;
        margin-bottom: 3vh;
        margin-left: 10px;
        /* margin-right: 5%; */
    }

    .admin-button:hover {
        opacity: 0.9;
    }

    .lb-ready {
        border-left: 5px solid rgb(23, 197, 0);
        border-left: 5px solid green;
    }

    .lb-review {
        border-left: 5px solid rgb(235, 86, 0);
    }

    .lb-start {
        border-left: 5px solid #6b0000;
        border-left: 5px solid #31256b;
        border-left: 5px solid #081b40;
        border-left: 5px solid #151030;
        border-left: 5px solid rgb(128, 29, 29);
    }


    .lesson-button-selected {
        background-color: rgb(28, 28, 32);
    }

    .lesson-button-selected p{
        font-weight: normal;
    }

    .lesson-button:hover {
        /* opacity: 70%; */
        background-color: rgb(28, 28, 32);

    }

    .lesson-content {
        height: 92vh;
        width: 81vw;
        float: right;
        overflow: hidden;
    }

    .main-content {
        background-color: rgb(14,14,16);
        height: 100%;
        width: 58vw;
        float: left;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
    }

    .side-content {
        height: 92vh;
        width: 23vw;
        float: right;
        justify-content: center;
        background-color: black;
    }

    .main-inner {
        /* margin-left: 1vw; */
        /* margin-top: 4%; */
        width: 100%;
    }

    .lesson-source {
        color: wheat;
        float: left;
        font-size: smaller;
    }

    .lesson-source a{
        color: rgb(226, 187, 187);
    }

    .lesson-video {
        display: flex;
        justify-content: center;
        align-items: center;
        /* float: left;
        justify-content: left; */
        height: 32.625vw;
        width: 58vw;
        background-image: url(../assets/logo.png);
        background-repeat:round;
    }

    .play-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(158, 159, 255);
        font-size: xxx-large;
        width: 70px;
        height: 70px;
        cursor: pointer;
    }

    .video-details h2 {
        margin-bottom: 0px;
    }

    .video-details {
        /* width: 95%; */
        width: calc(58vw - 30px);
        color: white;
        float: left;
        text-align: left;
        padding-bottom: 50px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .video-details span{
        background-color: none;
    }

    .lesson-card {
        float: left;
        min-height: 150px;
        /* width: 24vw; */
        width: 23vw;
        background-color: rgb(0, 0, 0);
        margin: 0 auto;
        margin-top: 2vh;
    }

    .lesson-resources {
        text-align: left;
        margin-left: 2vw;
        margin-right: 2vw;
        height: 50vh;
        overflow-y: scroll;
        scrollbar-width: none;
    }

    .lesson-resources a {
        display: block;
        margin-bottom: 2vh;
        color:rgb(201, 45, 45);
        color: rgb(218, 143, 143);
        color: rgb(158, 159, 255);
        /* color: #081b40; */
    }

    .lesson-resources a:hover {
        color: rgb(218, 166, 166);
        color: rgb(181, 182, 255);
    }

    .status-buttons {
        display: flex;
        flex-direction: column;
        /* width: 60%; */
        margin: 0 auto;
        /* margin-left: 1vw; */
        margin-top: 2vh;
        width: 55%;

    }


    .status-button{
        display: flex;
        /* border: 0.1px solid rgb(48, 42, 42); */
        margin: 0 auto;
        margin-top: 2px;
        height: 1vw;
        width: 99%;
        min-height: 50px;
        cursor: pointer;
        color: rgb(255, 255, 255);
        background-color: #888cff2d;;
    }

    .status-button p{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        font-size: small;
        font-weight: bold;
    }



    .bred:hover {
        background-color: #6b0000;
        background-color: #151030;
        background-color: rgb(128, 29, 29);
        /* background-color: #081b40; */
    }

    .byellow:hover {
        background-color: rgb(235, 86, 0);
    }

    .bgreen:hover {
        background-color: green;
    }

    .bred-selected {
        background-color: #6b0000;
        background-color: #151030;
        background-color: rgb(128, 29, 29);
        /* background-color: #081b40; */
    }

    .byellow-selected {
        background-color: rgb(235, 86, 0);
    }

    .bgreen-selected {
        background-color: green;
    }

    .card-title {
        display: flex;
        align-items: center;
        text-align: left;
        color: rgba(255, 255, 255, 0.85);
        height: 20%;
        width: 24vw;
        margin-left: 20px;
        margin-top: 1%;
        font-weight: bold;
    }

    .progress-container {
        background-color: blanchedalmond;
        margin-left: 20px;
    }

    ::-webkit-scrollbar-corner {
        background: #03002e;
    }

    ::-webkit-scrollbar-track {
        background: #010011;
    }

    .main-content::-webkit-scrollbar-thumb {
        background: #6b0000;
        background: #161620;
        background: rgb(31, 22, 26);

    }

    .lesson-bar::-webkit-scrollbar{
        display: none;
    }

    .main-content::-webkit-scrollbar-track {
        background: #161620;
        background: rgb(31, 22, 26);
    }

    .main-content:hover::-webkit-scrollbar-track {
        background: #161620;
        background: rgb(31, 22, 26);
    }

    .main-content::-webkit-scrollbar{
        display: none;
    }



    body::-webkit-scrollbar-thumb {
        display: none;
    }

}