    @media all and (min-width: 700px){

        .sidebar-container {
            background-color: #212121;
            background-color: #0a0a0a;
            width: 10rem;
            min-width: 10rem;
            max-width: 11rem;
            height: 100vh;
            position: fixed;
            top: 8vh;
            float: left;
        }


        .user-profile {
            display: flex;
            margin-top: 4vh;
            width: 100%;
            /* min-width: 202px;
            max-width: 222px; */
            max-height: 64px;
            margin-left: auto;
            margin-right: auto;
            /* border-top: 0.05pt solid #333333;
            border-bottom: 0.05pt solid #333333; */
            /* background-color: greenyellow; */
        }

        .navbar-outer {
            margin-top: 4vh;
        }


        .sidebar-container h1 {
            font-family: 'Brush Script MT', cursive;
            color: rgba(241, 242, 255, 0.781);
        }


    }