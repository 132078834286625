

.no-courses-error {
    display: flex;
    align-items: center;
    height: 10vh;
    width: 50%;
    color: rgb(182, 182, 182);
    margin: 3vw;
    background-color: #212121;
    border-style: dashed;
    border-color: black;
}

.no-courses-error p {
    margin: 0 auto;
}