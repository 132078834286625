
@media all and (max-width: 1px){
    

    .profile-contents {
        display: flex;
        align-items: center;
        margin: 0 auto;
        line-height: 10px; 
        color: white; 
        font-style: bolder; 
    }

    .profile-contents-inner {
        /* text-align: center;
        height: 64px; */
    }

    .user-img {
        display: table-row;
    }

    .user-img img{
        display: table-cell;
        vertical-align: top;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
    }

    .user-details {
        display: table;
        float: left;
        height: 64px;
        text-align: center;
        margin: 0 auto;
        margin-left: 10px;
    }


    .user-data-row {
        height: 50%;
        display: table-row;
    }

    .user-data-row img{
        width: 120px;
        display: table-cell;
        vertical-align: top;
    }
    .user-data-row span{
        display: table-cell;
        text-align: center;
        margin: 0 auto;
        line-height: 19px;
    }

    .align-up span{
        vertical-align: top;
    }

    .align-middle span{
        vertical-align: middle;
    }

    .align-down span{
        vertical-align: bottom;
    }

    
}