@media all and (min-width: 700px){

    .user-details {
        margin-right: 2vw;
    }

    .auth-button {
        /* float: right; */
    }

    .auth-button img {
        width: 120px;
    }

    .profile-contents {
        display: flex;
        align-items: center;
        height: 8vh;
        color: white; 
        font-style: bolder; 
        overflow: hidden;
    }

    .login-button {
        background-color: black;
        color: white;
        border: 1px solid white;
        cursor: pointer;
        border-radius: 10px;
    }

    .login-button:hover {
        opacity: 0.9;
    }




    
    
}