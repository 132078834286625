
@media all and (min-width: 700px){

    .page-container {
        width: calc(100vw - 10rem);
        min-width: calc(100vw - 11rem);
        max-width: calc(100vw - 10rem);
        height: 92vh;
        float: right;
        margin-top: 8vh;
        background-color: #161620;
        background-color: #000000;
        /* background-color: rgb(31, 20, 25); */
        overflow-y: scroll;
        /* vertical-align: top; */
    }


    .notice-bar-content {
        display: flex;
        width: 100%;
        height: 10vh;
        min-height: 80px;
        overflow: hidden;
        background-color: rgb(133, 0, 0);
        /* background-color: rgb(71, 13, 45); 
        background-color: rgb(9, 0, 48);  */

    }

    .page-content-wrapper{
        padding-left: 2vw;
        padding-right: 2vw;
        margin-top: 1vh;
    }

    .title-bar {
        float: left;
        clear: both;
        padding-top: 1vh;
        margin-left: 2vw;

    }

    .cards-grid {
        padding-top: 1vh;
        clear: both;
        display: block;
        /* background-color: #12121b; */
        /* height: 200px; */
        /* width:500px; */
        overflow-x: auto;
        /* overflow-y: none; */
        /* white-space:nowrap; */
    }

    .footer {
        display: block;
        color: white;
        height: 4vh;
        padding-bottom: 2vh;
        width: 100%;
        clear: both;
    }


    .notice-bar p {
        display: flex;
        align-items: center;
        margin: 0 auto;
        color: white;
        /* color: rgb(255, 166, 166); */
        font-size: 20px;
        font-weight: bold;
    }


    .title-bar h2 {
        /* color: black; */
        color: white;
        flex-grow: 1;
        font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
        font-size: 1.4vw;
        font-weight: 700;
        line-height: 1.25vw;
    }


    ::-webkit-scrollbar {
    width: 5px;
    height: 5px;;
    }

    ::-webkit-scrollbar-track {
    /* background: rgb(0, 3, 22); */
    background: #fafafa;
    background: #12121b;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;       
        /* border: 1px solid orange;   */
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #555;

    }

}